var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ApolloQuery',{attrs:{"query":require('@/graphql/report/report.gql'),"variables":{ id: _vm.report_id }},scopedSlots:_vm._u([{key:"default",fn:function({ result: { loading, error, data } }){return [(loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):(data && _vm.checkPermissions(data))?_c('div',[_c('v-tabs',{attrs:{"background-color":"primary","dark":"","left":""}},[(data.report && data.report.type == 'Single')?_c('v-tab',{attrs:{"left":""}},[_vm._v("Report")]):_vm._e(),(
                        data.report &&
                            data.report.metadata &&
                            data.report.metadata != 'null' &&
                            data.report.type == 'Dual'
                    )?_c('v-tab',[_vm._v(_vm._s(_vm.metadata(data).pending_tab_name))]):_vm._e(),(
                        data.report &&
                            data.report.metadata &&
                            data.report.metadata != 'null' &&
                            data.report.type == 'Dual'
                    )?_c('v-tab',[_vm._v(_vm._s(_vm.metadata(data).completed_tab_name))]):_vm._e(),(data.report && data.report.type == 'Single')?_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('iframe',{staticStyle:{"display":"block","width":"100%","height":"100vh"},attrs:{"src":data.report.source,"frameborder":"0","scrolling":"yes","seamless":"seamless"}})])],1):_vm._e(),(
                        data.report &&
                            data.report.metadata &&
                            data.report.metadata != 'null' &&
                            data.report.type == 'Dual'
                    )?_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('iframe',{staticStyle:{"display":"block","width":"100%","height":"100vh"},attrs:{"src":_vm.metadata(data).pending_requests_url,"frameborder":"0","scrolling":"yes","seamless":"seamless"}})])],1):_vm._e(),(
                        data.report &&
                            data.report.metadata &&
                            data.report.metadata != 'null' &&
                            data.report.type == 'Dual'
                    )?_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('iframe',{staticStyle:{"display":"block","width":"100%","height":"100vh"},attrs:{"src":_vm.metadata(data).completed_requests_url,"frameborder":"0","scrolling":"yes","seamless":"seamless"}})])],1):_vm._e()],1)],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }